<template>
  <Login />
</template>

<script>
import Login from './Login.vue';

export default {
  name: 'ResetPassword',
  components: { Login }
};
</script>